import { withAuth } from '@lib/hooks'
import { HttpRequestMethod } from 'http-core-constants'
import { type APIDonorDetails, type APISchedule } from '@shamaazi/mytennights'
import { callService } from '@lib/services'

export interface UpdateDonorDetailsRequest {
  ticket_number: string
  donor_details: APIDonorDetails
}

export module ScheduleAPI {
  export const search = withAuth(async (query: string, campaign: string) => {
    return callService('/api/schedule?' + new URLSearchParams({ query, campaign }).toString(), HttpRequestMethod.GET)
  })

  export const read = withAuth(async (donorId: string, scheduleId: string) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}`, HttpRequestMethod.GET)
  })

  export const refund = withAuth(async (donorId: string, scheduleId: string, night: number) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/refund/${night}`, HttpRequestMethod.POST)
  })

  export const setCancelled = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/cancel`, HttpRequestMethod.POST, { value })
  })

  export const setNight30 = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/night30`, HttpRequestMethod.POST, { value })
  })

  export const setTaxReceipt = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/tax_receipt`, HttpRequestMethod.POST, { value })
  })

  export const updateAutoEnroll = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/auto_enroll`, HttpRequestMethod.POST, { value })
  })
  export const updateGiftAid = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/gift_aid`, HttpRequestMethod.POST, { value })
  })
  export const resendConfirmation = withAuth(async (donorId: string, scheduleId: string) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/confirmation`, HttpRequestMethod.POST)
  })

  export const resendReceipt = withAuth(async (donorId: string, scheduleId: string, night: number) => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/receipt/${night}`, HttpRequestMethod.POST)
  })

  export const updateDonorDetails = async (donorId: string, scheduleId: string, data: UpdateDonorDetailsRequest): Promise<void> => {
    return callService(`/api/schedule/${donorId}/${scheduleId}/donor_details`, HttpRequestMethod.POST, data)
  }

  export const getTaxReceiptDocument = async (donorId: string, scheduleId: string): Promise<string> => {
    const response = await fetch(`/api/schedule/${donorId}/${scheduleId}/tax_receipt_document`, {
      method: HttpRequestMethod.GET
    })

    return await response.text()
  }

  export const fridayGivingSearch = withAuth(async (query: string) => {
    return callService('/api/friday_giving_schedule?' + new URLSearchParams({ query }).toString(), HttpRequestMethod.GET)
  })
  export const readFridayGiving = withAuth(async (donorId: string, fridayGivingScheduleId: string) => {
    return callService(`/api/friday_giving_schedule/${donorId}/${fridayGivingScheduleId}`, HttpRequestMethod.GET)
  })

  export const refundFridayGiving = withAuth(async (donorId: string, scheduleId: string, paymentDate: string) => {
    return callService(`/api/friday_giving_schedule/${donorId}/${scheduleId}/refund/${paymentDate}`, HttpRequestMethod.POST)
  })
  export const resendFridayGivingReceipt = withAuth(async (donorId: string, scheduleId: string, paymentDate: string) => {
    return callService(`/api/friday_giving_schedule/${donorId}/${scheduleId}/receipt/${paymentDate}`, HttpRequestMethod.POST)
  })
  export const setCancelledFridayGiving = withAuth(async (donorId: string, scheduleId: string, value: boolean) => {
    return callService(`/api/friday_giving_schedule/${donorId}/${scheduleId}/cancel`, HttpRequestMethod.POST, { value })
  })
}

export const isMyTenNights = (schedule: APISchedule): boolean => {
  return Boolean(schedule.campaign?.startsWith('mtn'))
}
