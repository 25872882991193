import { Fragment, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import time from '../service/time'
import { displayCurrency } from '@lib/services'
import { useMutation } from 'react-query'
import { getStripe } from '@shamaazi/mytennights'
import { getFridayGivingAmount } from './SearchResults'
import moment from 'moment'


export const Payment = ({ curr, payment, onRefund, payFees, onResendReceipt, hideStatus, schedule }) => {
  const [refunding, setRefunding] = useState(false)
  const [runState, setRunState] = useState(payment)

  const refund = async () => {
    const p = prompt(`Please type 'refund' to refund the payment for friday giving of date ${runState.payment_date}`)
    if (p !== 'refund') {
      return
    }

    setRefunding(true)
    await onRefund(runState.payment_date)
  }

  const { isLoading: retryLoading, isError: isRetryError, mutate: retryPayment } = useMutation(async () => {
    const stripe = await getStripe(schedule.charity_id)
    if (stripe === null) {
      throw new Error('Stripe object was not properly initialised')
    }
    const result = await stripe?.confirmCardPayment(payment?.payment_intent_client_secret ?? '', {
      payment_method: schedule?.payment_method
    })

    if (result?.error != null) {
      throw new Error(result?.error?.message)
    }

    return result?.paymentIntent?.status ?? ''
  }, {
    onSuccess: (data) => {
      setRunState({ ...runState, status: data })
    }
  })

  const resendReceipt = () => onResendReceipt(runState.payment_date)

  const weekly = <><br />Amount - {displayCurrency({ amount: runState.amount, currency: curr })}</>

  const mtn = <><br />Tip - {displayCurrency({ amount: schedule.tip_amount, currency: curr })}</>

  const fees = payFees
    ? <><br />Fee - {displayCurrency({ amount: schedule.tip_fees + schedule.fees, currency: curr })}</>
    : <></>
  return <Col key={runState.payment_date} xs={12} md={6} xl={4}>
    <Card className="my-3 rounded shadow">
      <Card.Body className="text-center">
        {runState.payment_status === 'succeeded' || runState.payment_status === 'failed'
          ? <Button className="mb-3" variant="outline-primary" onClick={resendReceipt}>Resend Receipt</Button>
          : <></>
        }
        <Card.Title>{ 'Maghreb'}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{time.formatDate(runState.payment_date)}</Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">{getFridayGivingAmount(schedule)}</Card.Subtitle>
        <Card.Text className="text-muted">
          {weekly}
          {mtn}
          {fees}
        </Card.Text>
        {!hideStatus && <Card.Text className={{ 'text-danger': runState.payment_status === 'cancelled' }}>Payment {runState.payment_status}</Card.Text>}
        {runState.payment_status === 'succeeded' && !refunding ? <Button variant="danger" onClick={refund}>Refund</Button> : <></>}
        {runState.payment_status === 'failed' && !retryLoading ? <Button variant="danger" onClick={retryPayment}>Retry</Button> : <></>}
        {isRetryError && <p className="mt-1 text-s text-center text-mtn-red min-h-xs">Error retrying</p>}
      </Card.Body>
    </Card>
  </Col>
}

export const RunBreakdown = ({ onRefund, schedule, onResendReceipt, runs }) => {
  // Sort by ascending payment date
  const payments = runs.sort((a, b) => moment(a.created_time).valueOf() - moment(b.created_time).valueOf())

  return <>
    <h3 className='font-bold my-2'>Weekly Payments</h3>
    <hr />
    <Row>
      {payments.map(p => <Payment
        curr={schedule.currency}
        key={p.payment_date}
        payFees={schedule.pay_fees}
        onRefund={onRefund}
        onResendReceipt={onResendReceipt}
        payment={p}
        schedule={schedule}
      />)}
    </Row>
  </>
}
