import { type FC } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { ProvideAuth } from '@lib/hooks'
import { AuthenticatedRoute, ErrorBoundary, ScrollToTop } from '@lib/components'

import { Search } from './pages/Search'
import { Schedule } from './pages/Schedule'
import { browserExceptions } from '@lib/services'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Login } from './pages/Login'
import { FGSearch } from './pages/FGSearch'
import { FGSchedule } from './pages/FGSchedule'

void browserExceptions.initialize()
const queryClient = new QueryClient()

export const App: FC = () => {
  return (
    <ErrorBoundary component={<h1>An error occurred - sorry!</h1>}>
      <ProvideAuth>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<AuthenticatedRoute loginComponent={<Login />}>
                <Search />
              </AuthenticatedRoute>} />
              <Route path="/donor/:donorId/schedule/:scheduleId" element={<AuthenticatedRoute loginComponent={<Login />}>
                <Schedule />
              </AuthenticatedRoute>} />
              <Route path="/friday-giving" element={<AuthenticatedRoute loginComponent={<Login />}>
                <FGSearch />
              </AuthenticatedRoute>} />
              <Route path="/donor/:donorId/friday-giving-schedule/:scheduleId" element={<AuthenticatedRoute loginComponent={<Login />}>
                <FGSchedule />
              </AuthenticatedRoute>} />
            </Routes>
          </Router>
        </QueryClientProvider>
      </ProvideAuth>
    </ErrorBoundary>
  )
}
