import { Col, Row } from 'react-bootstrap'
import time from '../service/time'
import { displayCurrency, getCountryByCode, GetCurrencyByString } from '@lib/services'
import { type FC } from 'react'
import { type FridayGivingDTO } from '@shamaazi/mytennights'
import { getFridayGivingAmount } from './SearchResults'

interface ScheduleSummaryProps {
  schedule: FridayGivingDTO
  donorId: string
}

export const FGScheduleSummary: FC<ScheduleSummaryProps> = ({ schedule }) => {
  const money = (value: number): string => displayCurrency({ amount: value, currency: GetCurrencyByString(schedule.currency) })
  const address = <>
    <Row className="mx-0">{schedule.donor_details.address_1}</Row>
    <Row className="mx-0">{getCountryByCode(schedule.donor_details.country)?.name}</Row>
    <Row className="mx-0">{schedule.donor_details.post_code}</Row>
  </>

  return <>
    <h1 className="mb-1">Schedule</h1>
    {schedule.cancelled ? <h6 className="text-danger">Cancelled</h6> : <></>}
    <h6 className="text-muted">Created at {time.formatTime(schedule.created_time)} on {time.formatDate(schedule.created_time)}</h6>
    <h6 className="text-muted">{schedule.timezone}</h6>
    <hr />
    <Row className="mx-0 mb-2"><Col className="px-0">Name:</Col><Col className="px-0">{schedule.donor_details.first_name + ' ' + schedule.donor_details.last_name}</Col>

    </Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Email:</Col><Col className="px-0">{schedule.email}
    </Col></Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Address:</Col><Col className="px-0">{address}</Col></Row>
    <hr />
    <Row className="mx-0 mb-2"><Col className="px-0">Charity:</Col><Col className="px-0">{schedule.charity_name}</Col></Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Weekly Amount:</Col><Col className="px-0">{money(schedule.amount)}</Col></Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Tip:</Col><Col className="px-0">{money(schedule.tip_amount)}</Col></Row>
    {schedule.pay_fees ? <Row className="mx-0 mb-2"><Col className="px-0">Transaction Fee:</Col><Col className="px-0">{money(schedule.fees)}</Col></Row> : <></>}
    <hr />
    <Row className="mx-0 mb-5"><Col className="px-0">Total:</Col><Col className="px-0">{getFridayGivingAmount(schedule)}</Col></Row>
    <hr/>
  </>
}

export default FGScheduleSummary
